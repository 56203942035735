/*
  Copyright 2018-2020 National Geographic Society
  Copyright 2021-2022 Impact Observatory

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorTemplate } from '../error-template';

export const NotEnabledComponent = ({ returnToHome, aboutLink, appName }) => {
  const { t } = useTranslation('admin');

  return (
    <ErrorTemplate
      type={
        <Typography align="center" className="marapp-qa-title" paragraph={true} variant="h1">
          Not Enabled
        </Typography>
      }
      message={
        <span>
          {t('The page you are looking for has not been enabled')}
          <span
            onClick={returnToHome}
            dangerouslySetInnerHTML={{
              __html: t('Please visit our landing page or learn about our product', {
                aboutLink,
              }),
            }}
          />
        </span>
      }
    >
      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          onClick={returnToHome}
          className="marapp-qa-button"
          color="secondary"
          variant="contained"
          size="large"
        >
          {t('Return to app', { value: appName })}
        </Button>
      </Box>
    </ErrorTemplate>
  );
};
