/*
 * Copyright 2018-2020 National Geographic Society
 *
 * Use of this software does not constitute endorsement by National Geographic
 * Society (NGS). The NGS name and NGS logo may not be used for any purpose without
 * written permission from NGS.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */
import { useEffect, useState } from 'react';
import { noop } from 'lodash';
import { IResponseMany } from '../interfaces';

/**
 * Custom hook that:
 * - Monitors only the initial page of a paginated request
 * - Extracts the total number of entities available (our api returns a valid value only for the initial page)
 * - If a callback function is provided, call this function with the new payload
 */
export function useInitialPaginationFetch(
  search: any,
  swrResponse: IResponseMany,
  callback: (params: IResponseMany | null) => void = noop
) {
  const [totalResults, setTotalResults] = useState(null);

  /**
   * Hook up search changes with resetting the total number of results.
   * Also, if a callback is provided, trigger the callback reset event
   * */
  useEffect(() => {
    setTotalResults(null);
    callback(null);
    swrResponse?.resetPage && swrResponse?.resetPage();
  }, [search]);

  /**
   * Hook that updates the total number of results only when requesting
   * for the first page of a paginated request
   * */
  useEffect(() => {
    if (!totalResults || swrResponse.size === 1) {
      setTotalResults(swrResponse?.meta?.results);
      callback(swrResponse);
    }
  }, [swrResponse?.meta, swrResponse?.size, totalResults]);

  return {
    totalResults,
  };
}
