/*
 * Copyright 2018-2020 National Geographic Society
 *
 * Use of this software does not constitute endorsement by National Geographic
 * Society (NGS). The NGS name and NGS logo may not be used for any purpose without
 * written permission from NGS.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *   https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import { groupBy, sortBy } from 'lodash';

export const TRANSFORM = {
  groupFilters(response) {
    const transformer = (payload) => {
      const filtersWithLabels = payload.map((filter) => ({
        ...filter,
        value: filter.value || '',
        label: filter.value || '',
        ...(filter.key === 'category' &&
          filter.value &&
          filter.value.indexOf('/') > 0 && {
            primary: filter.value.split('/')[0],
            label: filter.value.split('/')[1],
            isSubcategory: true,
          }),
        ...(typeof filter.value === 'boolean' && {
          label: filter.value ? 'Yes' : 'No',
          value: filter.value ? 'true' : 'false',
        }),
      }));

      // for categories with subcategories, aggregate count up one-level
      const filtersAggregated = filtersWithLabels.map((filter) => ({
        ...filter,
        ...(filter.key === 'category' && {
          // find subcategories with this value as primary, sum their counts to this one
          count: filtersWithLabels
            .filter((p) => p.isSubcategory === true && p.primary === filter.value)
            .reduce((c, m) => (c += m.count), filter.count),
        }),
      }));

      const availableFilters = groupBy(sortBy(filtersAggregated, 'value'), 'key');

      return availableFilters;
    };

    const result = {
      ...response,
      meta: {
        ...response.meta,
        filters: transformer(response.meta.filters),
      },
    };

    return result;
  },

  // We use the percentage for each category (in pixels) to calculate the total area for each category (in km2)
  // https://impactobservatory.atlassian.net/browse/IOPLAT-4586
  patchBiodiversityIntactness(response) {
    if (!response.data?.metrics) {
      return response;
    }

    const patchedMetrics = response.data.metrics.map((metric) => {
      if (metric.slug === 'biodiversity-intactness') {
        return patch(metric);
      }
      return metric;
    });

    return {
      ...response,
      data: {
        ...response.data,
        metrics: patchedMetrics,
      },
    };

    function patch({ metric, ...rest }) {
      const { area_km2, int_area, int_perc, ...percentile } = metric;
      const totalPixels = Object.keys(percentile).reduce((acc, curr) => {
        const value = percentile[curr];
        return acc + value;
      }, 0);
      const percentileInKm2 = Object.keys(percentile).reduce((acc, curr) => {
        const value = percentile[curr];
        const percent = value / totalPixels;
        return {
          ...acc,
          [curr]: Math.round(area_km2 * percent),
        };
      }, {});

      return {
        ...rest,
        metric: {
          ...percentileInKm2,
          area_km2,
          int_area,
          int_perc,
        },
      };
    }
  },

  flattenLayers(response) {
    return {
      ...response,
      data: response.data.map(flattenLayerConfig),
    };
  },
  STACCatalogId(response) {
    return {
      ...response,
      data: response.data.id,
    };
  },
  STACCollectionsIds(response) {
    return {
      ...response,
      data: response.data.collection_ids,
    };
  },
  STACCollectionAssets(response) {
    return {
      ...response,
      data: Object.keys(response.data.item_ids),
    };
  },
};

/**
 * Put layer.config props directly on the layer - include layer reference
 */
// TODO: add (layer: ILayerRaw) types when we move them into shared
export function flattenLayerConfig(layer: any) {
  const adaptedLayer = flattenEachLayerConfig(layer);

  if (!!adaptedLayer?.references?.length) {
    const adaptedReferences = layer.references.map(flattenEachLayerConfig);

    return {
      ...adaptedLayer,
      references: adaptedReferences,
    };
  }

  return {
    ...adaptedLayer,
  };
}

/**
 * Put layer.config props directly on the layer
 */
// TODO: add (layer: ILayerRaw): ILayer types when we move them into shared
function flattenEachLayerConfig(layer: any): any {
  const { config, ...rest } = layer;
  return {
    ...rest,
    ...config,
  };
}
